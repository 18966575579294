import React, { useContext } from "react";
import styles from './TabNavigation.module.css';
import { NavLink } from "react-router-dom";
import { LoggedInContext } from "../providers/LoggedInContext";
import { BrandCountryContext } from "../providers/BrandCountryProvider";

export default function HiQTabNavigation(){

    const handleNavClick = () => {
        window.scrollTo(0,0);
    }

    const {currentPage} = useContext(BrandCountryContext);

    return(
        <div className={`${styles.navTabBar} ${styles.hiq}`}>
            <div className={`${styles.tab} ${currentPage === 'Guidelines'? styles.selected : ''}`}> 
                <NavLink to="/hiq/guidelines"  onClick={handleNavClick}>Guidelines</NavLink>
                <div className={styles.expandingBar}></div>
            </div>
            <div className={`${styles.tab} ${currentPage === 'Implementation'? styles.selected : ''}`}> 
                <NavLink to="/hiq/implementation" onClick={handleNavClick}>Implementation</NavLink>
                <div className={styles.expandingBar}></div>
            </div>
            <div className={`${styles.tab} ${currentPage === 'Furniture Catalogue'? styles.selected : ''}`}> 
                <NavLink to="/hiq/catalogue" onClick={handleNavClick}>Furniture Catalogue</NavLink>
                <div className={styles.expandingBar}></div>
            </div>
            <div className={`${styles.tab} ${currentPage === 'Marketing Assets'? styles.selected : ''}`}> 
                <NavLink to="/hiq/marketingAssets" onClick={handleNavClick}>Marketing Assets</NavLink>
                <div className={styles.expandingBar}></div>
            </div>
        </div>
    )
}