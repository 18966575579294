import React, { useContext, useEffect, useState } from "react";
import styles from './Register.module.css';
import { useNavigate } from "react-router-dom";
import { LoggedInContext } from "../../providers/LoggedInContext";
import PrivacyPolicyViewer from "../../privacyPolicy/PrivacyPolicyViewer";
import Footer from "../../footer/Footer";
import FooterExternal from "../../footer/FooterExternal";

export default function Regsiter(){
    const{failedLogin,loggedIn,setLoggedIn,handleLogin,handleCheckLogin,backendEndpoint,setLoading} = useContext(LoggedInContext);

    const navigate = useNavigate();

    const [newFormData,setNewFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
        defaultCountry: '',
        defaultBrand: '',
        viewAllCountry: false,
        viewAllBrands: false,
        viewPremio: false,
        viewVulco: false,
        viewSuperService: false,
        viewEU: true,
        viewUK: false
    });
    const [passLength,setPassLength] = useState(false);
    const [passMatch,setPassMatch] = useState(false);
    const [createdAccount,setCreatedAccount] = useState(false);
    const [nextPage,setNextPage] = useState(false);
    const [policyAccepted,setPolicyAccepted] = useState(false);
    const [selectedBrandsArray,setSelectedBrandsArray] = useState([]);

    useEffect(() => {
        setSelectedBrandsArray(() => {
            let array = [];
            if(newFormData.viewPremio){
                array.push('Premio');
            }
            if(newFormData.viewVulco){
                array.push('Vulco');
            }
            if(newFormData.viewSuperService){
                array.push('Super Service');
            }
            return array;
        });
        if(newFormData.viewPremio === false){
            if(newFormData.defaultBrand === 'Premio'){
                setNewFormData((prev) => ({...prev,defaultBrand: '', defaultCountry: ''}));
            }
        }
        if(newFormData.viewVulco === false){
            if(newFormData.defaultBrand === 'Vulco'){
                setNewFormData((prev) => ({...prev,defaultBrand: '', defaultCountry: ''}));
            }
        }
        if(newFormData.viewSuperService === false){
            if(newFormData.defaultBrand === 'Super Service'){
                setNewFormData((prev) => ({...prev,defaultBrand: '', defaultCountry: ''}));
            }
        }
        if(newFormData.defaultBrand === 'Super Service'){
            setNewFormData((prev) => ({...prev, defaultCountry: 'Italy'}));
        }
    },[newFormData.viewPremio,newFormData.viewVulco,newFormData.viewSuperService,newFormData.defaultBrand]);

    useEffect(() => {
        setNewFormData((prev) => ({...prev,viewAllCountry: selectedBrandsArray.length > 1? true : false}));
        if(selectedBrandsArray.length === 1){
            if(selectedBrandsArray[0] === 'Premio'){
                console.log('Premio');
                setNewFormData((prev) => ({...prev,defaultBrand: 'Premio'}));
            } else if(selectedBrandsArray[0] === 'Vulco'){
                setNewFormData((prev) => ({...prev,defaultBrand: 'Vulco'}));
            } else if(selectedBrandsArray[0] === 'Super Service'){
                setNewFormData((prev) => ({...prev,defaultBrand: 'Super Service', defaultCountry: 'Italy'}));
            }
        }
    },[selectedBrandsArray])

    const handlePolicyCheckboxChange = (e) => {
        setPolicyAccepted(e.target.checked);
    }

    const passwordLength = () => {
        if(newFormData.password.length >= 8){
            setPassLength(true);
        } else {
            setPassLength(false);
        }
    }

    const passwordsMatch = () => {
        if(newFormData.password !== '' && newFormData.password2 !== '' && newFormData.password === newFormData.password2){
            setPassMatch(true);
        } else {
            setPassMatch(false);
        }
    }

    const handleChange = (e) => {
        setNewFormData((prev) => ({...prev,[e.target.name]: e.target.value}));
    }
    const handleCheckboxChange = (e) => {
        setNewFormData((prev) => ({...prev,[e.target.name]: e.target.checked}));
    }

    useEffect(() => {
        passwordLength();
        passwordsMatch();
    },[newFormData.password,newFormData.password2]);

    useEffect(() => {
        handleCheckLogin();
    },[]);

    const checkEmailAlreadyInUse = async (email) => {
        return fetch(backendEndpoint+'/emailCheck',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email}),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            return recObj.status;
        })
        .catch((err) => console.log(err));
    }

    const handleAddUser = async () => {
        setLoading(true);
        fetch(backendEndpoint+'/usersSelf',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newFormData),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            setCreatedAccount(recObj.status);
            setNextPage(true);
            setLoading(false);
        })
        .catch((err) => console.log(err));
    }

    const registerFunction = async (e) => {
        setLoading(true);
        e.preventDefault();
        const emailInUse = await checkEmailAlreadyInUse(newFormData.email);
        setLoading(false);
        if(emailInUse){
            alert('Email already in use');
        } else {
            if(newFormData.firstName === '' ||
            newFormData.lastName === '' ||
            newFormData.email === '' ||
            newFormData.password === '' ||
            newFormData.password2 === '' ||
            newFormData.defaultCountry === '' ||
            newFormData.defaultBrand === ''){
                alert(`All fields must be completed before submitting`);
            } else {
                if(!policyAccepted){
                    alert(`The Privacy Policy must be accepted before submitting`);
                } else {
                    handleAddUser();
                }
            }
        }
    }
    const navigatelogin = () => {
        navigate('/');
    }
    const optionObject = {
        "Premio": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Belgium">Belgium</option>
            <option value="Bulgaria">Bulgaria</option>
            <option value="Czechia">Czechia</option>
            <option value="Germany">Germany</option>
            <option value="Greece">Greece</option>
            <option value="Hungary">Hungary</option>
            <option value="The Netherlands">The Netherlands</option>
            <option value="Poland">Poland</option>
            <option value="Romania">Romania</option>
            <option value="Slovakia">Slovakia</option>
            <option value="Switzerland">Switzerland</option>
        </>,
        "Vulco": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Bosnia">Bosnia</option>
            <option value="Croatia">Croatia</option>
            <option value="France">France</option>
            <option value="North Macedonia">North Macedonia</option>
            <option value="Portugal">Portugal</option>
            <option value="Serbia">Serbia</option>
            <option value="Slovenia">Slovenia</option>
            <option value="Spain">Spain</option>
        </>,
        "Super Service": 
        <>
            <option hidden value="">Select a Country</option>
            <option value="Italy">Italy</option>
        </>,
        "":
        <>
            <option hidden value=""></option>
        </>

    }
    const paneSwitcher = () => {
        // return successPage();
        if(createdAccount && nextPage){
            return successPage();
        } else if (!createdAccount && nextPage) {
            return errorPage();
        } else {
            return registerForm();
        }
    }
    const successPage = () => {
        return (
            <div className={styles.paneLower2}>
                <p>Account created successfully, awaiting authorisation</p>
            </div>
        )
    }
    const errorPage = () => {
        return (
            <div className={styles.paneLower2}>
                <p>Error. Please try again</p>
            </div>
        )
    }
    const registerForm = () => {
        return(
            <form onSubmit={registerFunction} className={styles.paneLower}>
                <h2>Register</h2>
                <div className={styles.passwordPane}>
                    <label for="email">Email</label>
                    <input id="email" type="text" name="email" onChange={handleChange} value={newFormData.email}/>
                    <div className={styles.namesDiv}>
                        <div>
                            <label for="firstName">First Name</label>
                            <input id="firstName" type="text" name="firstName" onChange={handleChange} value={newFormData.firstName}/>
                        </div>
                        <div>
                            <label for="lastName">Last Name</label>
                            <input id="lastName" type="text" name="lastName" onChange={handleChange} value={newFormData.lastName}/>
                        </div>
                    </div>
                    <hr/>
                    <label for="password">Password</label>
                    <input id="password" type="password" name="password" onChange={handleChange} value={newFormData.password}/>
                    <p className={styles.errorMessage} style={{color: `${passLength? 'green' : 'red'}`, display: newFormData.password.length === 0? 'none' : 'block'}}>{passLength? `Password is at least 8 characters` : `Password must be at least 8 characters`}</p>
                    <label for="password2">Re-Type</label>
                    <input id="password2" type="password" name="password2" onChange={handleChange} value={newFormData.password2}/>
                    <p className={styles.errorMessage} style={{color: `${passMatch? 'green' : 'red'}`,display: newFormData.password2.length === 0? 'none' : 'block'}}>{passMatch? 'Passwords Match' : `Passwords Don't Match`}</p>
                    <hr/>
                    <h4>Select Brand and Country</h4>
                    <p className={styles.brandDisclaimer}>Select the brands you require access to. By selecting "View all countries..." you'll be able to see all countries within the brands you've chosen (this option is automatically applied if more than one brand is selected).</p>
                    <div className={`${styles.namesDiv} ${styles.brandSelectDiv}`}>
                        <div>
                            <label><p>Premio</p><input type="checkbox" name="viewPremio" onChange={handleCheckboxChange} checked={newFormData.viewPremio}/></label>
                            <label><p>Vulco</p><input type="checkbox" name="viewVulco" onChange={handleCheckboxChange} checked={newFormData.viewVulco}/></label>
                            <label><p>Super Service</p><input type="checkbox" name="viewSuperService" onChange={handleCheckboxChange} checked={newFormData.viewSuperService}/></label>
                        </div>
                        <label><p className={`${selectedBrandsArray.length > 1 || selectedBrandsArray.length === 0? styles.disabledLabel : ''}`}>View all countries within selected brand{selectedBrandsArray.length > 1? 's' : ''}</p><input type="checkbox" name="viewAllCountry" onChange={handleCheckboxChange} checked={newFormData.viewAllCountry} disabled={selectedBrandsArray.length > 1 || selectedBrandsArray.length === 0}/></label>
                    </div>
                    <p className={styles.brandDisclaimer}>Make sure to choose a default brand and country, these will be automatically applied whenever you log in.</p>
                    <div className={styles.namesDiv}>
                        <div>
                            <label for="defaultBrand">Default Brand</label>
                            <select id="defaultBrand" type="text" name="defaultBrand" onChange={handleChange} value={newFormData.defaultBrand} disabled={selectedBrandsArray.length === 0}>
                                {selectedBrandsArray.length === 0? <option hidden value=""></option> : '' }
                                <option hidden value="">Select a Brand</option>
                                <option hidden={!newFormData.viewPremio} value="Premio">Premio</option>
                                <option hidden={!newFormData.viewVulco} value="Vulco">Vulco</option>
                                <option hidden={!newFormData.viewSuperService} value="Super Service">Super Service</option>
                            </select>
                        </div>
                        <div>
                            <label for="defaultCountry">Default Country</label>
                            <select id="defaultCountry" type="text" name="defaultCountry" onChange={handleChange} value={newFormData.defaultCountry} disabled={newFormData.defaultBrand === '' || selectedBrandsArray.length === 0}>
                                {optionObject[newFormData.defaultBrand]}
                            </select>
                        </div>
                    </div>
                    <hr/>
                    <div className={styles.acceptPolicy}>
                        <input type="checkbox" id="acceptPolicy" onChange={handlePolicyCheckboxChange} isChecked={policyAccepted}/>
                        <p className={styles.statement}><label for="acceptPolicy">I have read and accept the </label><PrivacyPolicyViewer/></p>
                    </div>
                </div>
                <div className={styles.footer}>
                    <input type="submit" value="Create Account" disabled={!policyAccepted}/>
                    <button type="button" onClick={navigatelogin}>Cancel</button>
                </div>
            </form>
        )
    }

    return(
        <>
            <main className={styles.background}>
                <div className={styles.overlay}></div>
                <div className={styles.paneContainer}>
                    <div className={styles.pane}>
                        <div className={styles.paneUpper}>
                            <span><strong>Goodyear</strong> Retail Brand Hub</span>
                        </div>
                        {paneSwitcher()}
                    </div>
                </div>
            </main>
            <FooterExternal />
        </>
    )
}