import React, { useContext, useEffect} from "react";
import styles from './GeneralAssets.module.css';
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import { useNavigate } from "react-router-dom";
import TabNavigation from "../../tabNavigation/TabNavigation";
import { LoggedInContext } from "../../providers/LoggedInContext";
import HiQTabNavigation from "../../tabNavigation/HiQTabNavigation";

export default function HiQGeneralAssets(){

    const { linkObject , handleGetLinkObject } = useContext(LoggedInContext);
    const { brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange } = useContext(BrandCountryContext);

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
        handleGetLinkObject();
    },[]);

    return(
        <div className={styles.container}>
            <HiQTabNavigation selected={brand}/>
            {linkObject?  <iframe src={linkObject.implementation["HiQ"]} frameborder="0" allowfullscreen webkitallowfullscreen msallowfullscreen></iframe> : ''}
        </div>
    )
}