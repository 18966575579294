import React, { useState , createContext, useContext, useEffect } from "react";
import { LoggedInContext } from "./LoggedInContext";

const BrandCountryContext = createContext();

function BrandCountryProvider({children}) {

    const pageObject = {
        '/hiq/home': 'Home',
        '/hiq/implementation': 'Implementation',
        '/hiq/guidelines': 'Guidelines',
        '/hiq/catalogue': 'Furniture Catalogue',
        '/hiq/marketingAssets': 'Marketing Assets',
        '/hiq/users': 'System Users',
        '/hiq/privacyInt':  'Privacy Policy',
        '/hiq/cookiesInt': ' Cookie Policy',
        '/home': 'Home',
        '/generalAssets': 'General Assets',
        '/guidelines': 'Guidelines',
        '/logosSignage': 'Logos & Signage',
        '/marketingAssets': 'Marketing Assets',
        '/users': 'System Users',
        '/privacyInt':  'Privacy Policy',
        '/cookiesInt': ' Cookie Policy'
    };

    const {loggedIn,user,handleLogin} = useContext(LoggedInContext);

    const [brand,setBrand] = useState(user.defaultBrand);
    const [country,setCountry] = useState(user.defaultCountry);
    const [disable,setDisable] = useState(false);
    const [changerActive,setChangerActive] = useState(!(brand !== '' && country !== ''));
    const [currentPage,setCurrentPage] = useState(pageObject[window.location.pathname]);
    const handlePageChange = (path) => {
        let newPath;
        if(path.includes('/boxTest')){
            newPath = path.slice(8);
        } else {
            newPath = path;
        }
        setCurrentPage(pageObject[newPath])
    }

    useEffect(() => {
        setBrand(user.defaultBrand);
        setCountry(user.defaultCountry);
    },[user])

    return (
        <BrandCountryContext.Provider value={{brand,setBrand,country,setCountry,disable,setDisable,currentPage,handlePageChange,changerActive,setChangerActive}}>
            {children}
        </BrandCountryContext.Provider>
    )
}

export { BrandCountryProvider, BrandCountryContext };