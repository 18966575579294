import React, { useContext , useState , useEffect , useRef } from "react";
import styles from './UserIcon.module.css';
import { ReactComponent as Cross } from '../../components/imgs/icons/cross-33.svg'
import { LoggedInContext } from "../providers/LoggedInContext";
import { NavLink } from "react-router-dom";

export default function HiQUserIcon(){

    const {user,handleLogOut} = useContext(LoggedInContext);

    const [showOptions,setShowOptions] = useState(true);
    const [expandedWidth,setExpandedWidth] = useState(0);

    const onResizeFunction = () => {
        const newViewportWidth = window.innerWidth;
        setShowOptions(true);
        elementRef.current.style.setProperty("--container-max-width", `auto`);
        getSize();
    }

    const elementRef = useRef(null);

    useEffect(() => {
        onResizeFunction();
        window.addEventListener('resize', onResizeFunction);
        return () => {
            window.removeEventListener('resize', onResizeFunction);
        }
    },[]);

    const toggleShow = () => {
        setShowOptions((prev) => !prev);
    }

    const getSize = () => {
        if(elementRef){
            if(elementRef.current.scrollWidth > 0){
                setExpandedWidth(elementRef.current.scrollWidth);
            }
        }
    }

    useEffect(() => {
        getSize();
    },[elementRef.current]);

    useEffect(() => {
        if(expandedWidth > 0){
            elementRef.current.style.setProperty("--container-max-width", `${expandedWidth}px`);
            setShowOptions(false);
        }
    },[expandedWidth]);

    return(
        <div className={`${styles.container} ${styles.hiq}`}>
            <div id="expandingCon" className={`${styles.expandingContainer} ${showOptions? '' : styles.notExpanded}`} ref={elementRef}>
                <div className={styles.circularIcon} onClick={toggleShow}>
                    {user.firstName[0]+user.lastName[0]}
                </div>
                <div className={`${styles.nameEmailCon} ${showOptions? styles.expanded : ''}`} onClick={toggleShow}>
                    <p className={styles.name}>{user.firstName} {user.lastName}</p>
                    <p className={styles.email}>{user.email}</p>
                </div>
                <button className={styles.logout} onClick={handleLogOut}>Sign Out</button>
            </div>
        </div>
    )
}