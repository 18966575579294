import React, { useContext, useEffect } from "react";
import styles from '../cookies/CookiesInternal.module.css';
import FooterExternal from "../../footer/FooterExternal";
import CookiePolicyContent from "../../cookieBanner/CookiePolicyContent";
import { useNavigate } from "react-router-dom";
import { BrandCountryContext } from "../../providers/BrandCountryProvider";

export default function HiQCookiesInternal(){

    const {brand,handlePageChange} = useContext(BrandCountryContext);

    useEffect(() => {
        handlePageChange(window.location.pathname);
    },[]);

    return (
        <>
            <div className={`${styles.container} ${styles.hiq}`}>
                <CookiePolicyContent />
            </div>
            <div className={styles.background} style={{backgroundImage: 'url(./imgs/home/HiQ_Redditch_High.jpg)'}}> </div>
        </>
    )
}