import React, { useContext, useEffect, useState } from "react";
import styles from '../register/Register.module.css';
import { useNavigate } from "react-router-dom";
import { LoggedInContext } from "../../providers/LoggedInContext";
import FooterExternal from "../../footer/FooterExternal";
import { ReactComponent as HiQlogo } from '../../imgs/logos/HiQ.svg';
import HiQFooterExternal from "../../footer/HiQFooterExternal";

export default function HiQLogin(){
    const{failedLogin,loggedIn,setLoggedIn,handleLogin,handleCheckLogin,failedLoginMessage} = useContext(LoggedInContext);

    const navigate = useNavigate();

    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');

    const handleOnChangeEmail = (e) => {
        setEmail(e.target.value);
    }

    const handleOnChangePassword = (e) => {
        setPassword(e.target.value);
    }

    useEffect(() => {
        handleCheckLogin();
    },[]);

    useEffect(() => {
        if(loggedIn){
            navigate('/hiq');
        }
    },[loggedIn]);

    const loginFunction = (e) => {
        e.preventDefault();
        handleLogin(email,password);
    }

    const navigateRegister = () => {
        navigate('/hiq/register')
    }

    return(
        <>
            <main className={`${styles.background} ${styles.hiq}`}>
                <div className={styles.overlay}></div>
                <div className={styles.paneContainer}>
                    <div className={styles.pane}>
                        <div className={styles.paneUpper}>
                            <span><strong>Goodyear</strong> Retail Brand Hub</span><div className={styles.divider}></div><div className={styles.svgCon}><HiQlogo/></div>
                        </div>
                        <form onSubmit={loginFunction} className={styles.paneLower}>
                            <h2>Welcome</h2>
                            <div className={styles.passwordPane}>
                                <label for="email">Email</label>
                                <input id="email" type="text" name="email" onChange={handleOnChangeEmail} value={email}/>
                                <label for="password">Password</label>
                                <input id="password" type="password" name="password" onChange={handleOnChangePassword} value={password}/>
                                <p className={styles.errorMessage}>{failedLogin? failedLoginMessage : ''}</p>
                            </div>
                            <div className={styles.footer}>
                                <input type="submit" value="Login" />
                                <button onClick={navigateRegister}>Register</button>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <HiQFooterExternal />
        </>
    )
}