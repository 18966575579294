import React, { useContext, useEffect, useState } from "react";
import styles from './BrandCountrySelect.module.css';
import CountryButton from "./CountryButton";
import BrandButton from "./BrandButton";
import { BrandCountryContext } from "../providers/BrandCountryProvider";
import LanguageButton from "./LanguageButton";
import { ReactComponent as Premio } from '../imgs/logos/Premio.svg';
import { ReactComponent as PremioDouble } from '../imgs/logos/PremioDoubleOLeft.svg';
import { ReactComponent as Vulco } from '../imgs/logos/Vulco.svg';
import { ReactComponent as SuperService } from '../imgs/logos/SuperService.svg';
import { ReactComponent as HiQ } from '../imgs/logos/HiQ.svg';
import { LoggedInContext } from "../providers/LoggedInContext";
import theme from '../../themes/Themes.module.css';
import FlagIcons from "../flagIcons/FlagIcons";

export default function HiQBrandCountrySelect(){

    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,currentPage,changerActive,setChangerActive,themeName} = useContext(BrandCountryContext);

        return (
            <div className={`${styles.container} ${styles.hiq} ${disable? styles.disable : ''}`}>
                <div className={`${styles.bannerContainer}`}>
                    <div className={`${styles.pageHeading} `}>
                        <h1>{currentPage}</h1>
                    </div>
                </div>  
            </div>
        )
    
}