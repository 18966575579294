import React, { useContext, useEffect, useState } from "react";
import styles from '../register/Register.module.css';
import { useNavigate, useParams } from "react-router-dom";
import { LoggedInContext } from "../../providers/LoggedInContext";
import FooterExternal from "../../footer/FooterExternal";
import PrivacyPolicyViewer from "../../privacyPolicy/PrivacyPolicyViewer";
import { ReactComponent as HiQlogo } from '../../imgs/logos/HiQ.svg';

export default function HiQNewUserLogin(){

    const navigate = useNavigate();

    const {backendEndpoint,setLoading} = useContext(LoggedInContext);

    const {inviteCode} = useParams();

    const [validCode,setValidCode] = useState(false);
    const [validEmail,setValidEmail] = useState('');
    const [password1,setPassword1] = useState('');
    const [password2,setPassword2] = useState('');
    const [passLength,setPassLength] = useState(false);
    const [passMatch,setPassMatch] = useState(false);
    const [passChangeSuccess,setPassChangeSuccess] = useState(false);
    const [email,setEmail] = useState('');
    const [policyAccepted,setPolicyAccepted] = useState(false);

    const handleCheckboxChange = (e) => {
        setPolicyAccepted(e.target.checked);
    }
    
    useEffect(() => {
        handleCheckCode(inviteCode);
    },[])

    const handleCheckCode = async (code) => {
        setLoading(true);
        const headers = new Headers();
        headers.append('Content-Type','application/x-www-form-urlencoded');
        fetch(backendEndpoint+`/invite/${code}`,{
            method: 'GET',
            headers: headers,
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            setValidCode(recObj.status === 'valid');
            setLoading(false);
        })
        .catch((err) => console.log(err));
    }
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }
    const handleChangePassword1 = (e) => {
        setPassword1(e.target.value);
    }
    const handleChangePassword2 = (e) => {
        setPassword2(e.target.value);
    }

    useEffect(() => {
        passwordLength();
        passwordsMatch();
    },[password1,password2]);

    const handleCheckEmail = () => {
        setLoading(true);
        fetch(backendEndpoint+'/inviteAuth',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({inviteCode,email}),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            if(recObj.status === 'valid'){
                setValidEmail(email);
            } else {
                setValidEmail('invalid');
            }
            setLoading(false);
        })
        .catch((err) => console.log(err));
    }
    const handleChangePassword = () => {
        setLoading(true);
        if(passMatch && passLength){
            fetch(backendEndpoint+'/createPassword',{
                method: 'PUT',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({inviteCode,email,password1}),
                credentials: "include"
            })
            .then((res) => res.text())
            .then((data) => {
                const recObj = JSON.parse(data);
                if(recObj.status === 'password changed'){
                    setPassChangeSuccess(true);
                } else {
                    setPassChangeSuccess(false);
                    alert('User not found');
                    setValidEmail('invalid');
                }
                setLoading(false);
            })
            .catch((err) => console.log(err));
        } else {
            alert(`Passwords don't match or don't meet the length requirement`);
            setLoading(false);
        }
    }
    const handleNavigateLogin = () => {
        navigate('/hiq/login');
    }
    const emailPane = () => {
        return(
            <div className={`${styles.paneLower}`}>
                <h2>Welcome</h2>
                <div className={styles.emailPane}>
                    <p>Your account has been pre-created on your behalf as a prospective user of Goodyear Retail Brand Hub. To complete the registration, please enter your email below.</p>
                    <br/>
                    <p>If you do not wish to complete the registration or would like your data removed, please contact us at it@stratela.co.uk for assistance with deleting your account and information.</p>
                    <br/>
                    <label>Enter your email address:</label>
                    <input type="text" onChange={handleChangeEmail} value={email}/>
                    <p className={styles.errorMessage}>{validEmail === 'invalid'? 'Invalid email address' : ''}</p>
                    <br/>
                    <div className={styles.acceptPolicy}>
                        <input type="checkbox" id="acceptPolicy" onChange={handleCheckboxChange} isChecked={policyAccepted}/>
                        <p className={styles.statement}><label for="acceptPolicy">I have read and accept the </label><PrivacyPolicyViewer/></p>
                    </div>
                </div>
                <div className={styles.footer}>
                    <button className={styles.nextBtn} onClick={handleCheckEmail} disabled={!policyAccepted}>Next</button>
                </div>
            </div>
        )
    }
    const passwordPane = () => {
        return(
            <div className={`${styles.paneLower}`}>
                <h2>Set Password</h2>
                <div className={styles.passwordPane}>
                    <label>Enter your new password</label>
                    <input type="password" onChange={handleChangePassword1} value={password1}/>
                    <p className={styles.errorMessage} style={{color: `${passLength? 'green' : 'red'}`, opacity: password1.length === 0? 0 : 1}}>{passLength? `Password is at least 8 characters` : `Password must be at least 8 characters`}</p>
                    <label>Re-enter your new password</label>
                    <input type="password" onChange={handleChangePassword2} value={password2}/>
                    <p className={styles.errorMessage} style={{color: `${passMatch? 'green' : 'red'}`,opacity: password2.length === 0? 0 : 1}}>{passMatch? 'Passwords Match' : `Passwords Don't Match`}</p>
                </div>
                <div className={styles.footer}>
                    <button className={styles.nextBtn} onClick={handleChangePassword} disabled={!passMatch}>Next</button>
                </div>
            </div>
        )
    }
    const resultPane = () => {
        return (
            <div className={`${styles.paneLower} ${styles.invalid}`}>
                <p>Password updated successfully</p>
                <div className={styles.footer}>
                    <button className={styles.nextBtn} onClick={handleNavigateLogin}>Go to Login</button>
                </div>
            </div>
        )
    }
    const paneSwitcher = () => {
        if(validEmail === 'invalid' || validEmail === ''){
            return emailPane();
        } else {
            if(passChangeSuccess){
                return resultPane();
            } else {
                return passwordPane();
            }
        }
    }
    const passwordLength = () => {
        if(password1.length >= 8){
            setPassLength(true);
        } else {
            setPassLength(false);
        }
    }

    const passwordsMatch = () => {
        if(password1 !== '' && password2 !== '' && password1 === password2){
            setPassMatch(true);
        } else {
            setPassMatch(false);
        }
    }

if(validCode){
    return (
        <>
            <main className={`${styles.background} ${styles.hiq}`}>
                <div className={styles.overlay}></div>
                <div className={styles.paneContainer}>
                    <div className={styles.pane}>
                        <div className={styles.paneUpper}>
                            <span><strong>Goodyear</strong> Retail Brand Hub</span><div className={styles.divider}></div><div className={styles.svgCon}><HiQlogo/></div>
                        </div>
                            {paneSwitcher()}
                    </div>
                </div>
            </main>
            <FooterExternal />
        </>
    )
} else {
    return (
        <>
            <main className={`${styles.background} ${styles.hiq}`}>
            <div className={styles.overlay}></div>
                <div className={styles.paneContainer}>
                    <div className={styles.pane}>
                        <div className={styles.paneUpper}>
                            <span><strong>Goodyear</strong> Retail Brand Hub</span><div className={styles.divider}></div><div className={styles.svgCon}><HiQlogo/></div>
                        </div>
                        <div className={`${styles.paneLower} ${styles.invalid}`}>
                            <p>Invalid Invite</p>
                            <div className={styles.footer}>
                                <button onClick={handleNavigateLogin}>Go to Login</button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <FooterExternal />
        </>
    )
}
    
}