import React, { useContext , useEffect } from "react";
import styles from './Home.module.css';
import BrandCountrySelect from "../../brandCountrySelect/BrandCountrySelect";
import { useNavigate } from "react-router-dom";
import { BrandCountryContext } from "../../providers/BrandCountryProvider";
import HomeButton from "./HomeButtons";
import HiQHomeButton from "./HiQHomeButtons";

export default function HiQHome(){

    const {brand,setBrand,country,setCountry,language,setLanguage,disable,setDisable,handlePageChange} = useContext(BrandCountryContext);

    const navigate = useNavigate();

    const handleNavClick = (path) => {
        window.scrollTo(0,0);
        navigate('/'+path);
    }

    useEffect(() => {
        setDisable(false);
        handlePageChange(window.location.pathname);
    },[]);

    const guidelinesContent = [
        'Guidelines'
    ];
    const logosContent = [
        'Furniture Catalogue'
    ];
    const marketingContent = [
        'Marketing Images',
        'Social Adverts',
        'Artwork'
    ];
    // 'Container Adverts',
    //     'Digital Adverts',
    //     'Digital Signage Adverts',
    //     'Print Adverts',
    //     'Web Adverts',
    const generalContent = [
        'Briefing Document',
        'Vehicle Livery Examples',
        'Store Portfolio'
    ];

    return(
        <div className={styles.container}>
            <div className={styles.buttonContainer}>
                <HiQHomeButton handleNavClick={handleNavClick} content={guidelinesContent} pagePath={'hiq/guidelines'} name={'Guidelines'} tagLine={'Brand Rules & Standards'} />
                <HiQHomeButton handleNavClick={handleNavClick} content={generalContent} pagePath={'hiq/implementation'} name={'Implementation'} tagLine={'Briefing & Implementation Examples'} />
                <HiQHomeButton handleNavClick={handleNavClick} content={logosContent} pagePath={'hiq/catalogue'} name={'Furniture Catalogue'} tagLine={'Furniture & Signage'} />
                <HiQHomeButton handleNavClick={handleNavClick} content={marketingContent} pagePath={'hiq/marketingAssets'} name={'Marketing Assets'} tagLine={'Web & Print Adverts'} />
            </div>
        </div>
    )
}