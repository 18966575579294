import React from "react";
import styles from './Footer.module.css';
import { NavLink } from "react-router-dom";
import { ReactComponent as Help } from './../imgs/icons/help.svg';

export default function HiQFooterExternal(){

    const handleNavClick = () => {
        window.scrollTo(0,0);
    }

    return (
        <footer className={`${styles.external} ${styles.hiq}`}>
            <div className={styles.helpCon}>
                <div className={styles.helpIcon}>
                    <Help />
                </div>
                <p>For any help or assistance,</p>
                <p>please reach out to:</p>
            </div>
            <div className={styles.footerFooter}>
                <div className={styles.contact}>info@goodyear-retail-brand-hub.com</div>
                <div className={styles.links}>
                    <NavLink to="/hiq/login" onClick={handleNavClick}>Login</NavLink>
                    <NavLink to="/hiq/register" onClick={handleNavClick}>Register</NavLink>
                    <NavLink to="/hiq/cookiePolicy" onClick={handleNavClick}>Cookie Policy</NavLink>
                    <NavLink to="/hiq/privacyPolicy" onClick={handleNavClick}>Privacy Policy</NavLink>
                </div>
                <div className={styles.copyright}>© Stratela Ltd 2024</div>
            </div>
        </footer>
    )
}