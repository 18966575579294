import React, { useContext, useEffect, useState } from "react";
import styles from './Register.module.css';
import { useNavigate } from "react-router-dom";
import { LoggedInContext } from "../../providers/LoggedInContext";
import PrivacyPolicyViewer from "../../privacyPolicy/PrivacyPolicyViewer";
import Footer from "../../footer/Footer";
import FooterExternal from "../../footer/FooterExternal";
import { ReactComponent as HiQlogo } from '../../imgs/logos/HiQ.svg';
import HiQFooterExternal from "../../footer/HiQFooterExternal";

export default function HiQRegsiter(){
    const{failedLogin,loggedIn,setLoggedIn,handleLogin,handleCheckLogin,backendEndpoint,setLoading} = useContext(LoggedInContext);

    const navigate = useNavigate();

    const [newFormData,setNewFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        password2: '',
        defaultCountry: 'HiQ',
        defaultBrand: 'UK',
        viewAllBrands: false,
        viewAllCountry: false,
        viewPremio: false,
        viewVulco: false,
        viewSuperService: false,
        viewEU: false,
        viewUK: true
    });
    const [passLength,setPassLength] = useState(false);
    const [passMatch,setPassMatch] = useState(false);
    const [createdAccount,setCreatedAccount] = useState(false);
    const [nextPage,setNextPage] = useState(false);
    const [policyAccepted,setPolicyAccepted] = useState(false);

    const handleCheckboxChange = (e) => {
        setPolicyAccepted(e.target.checked);
    }

    const passwordLength = () => {
        if(newFormData.password.length >= 8){
            setPassLength(true);
        } else {
            setPassLength(false);
        }
    }

    const passwordsMatch = () => {
        if(newFormData.password !== '' && newFormData.password2 !== '' && newFormData.password === newFormData.password2){
            setPassMatch(true);
        } else {
            setPassMatch(false);
        }
    }

    const handleChange = (e) => {
        setNewFormData((prev) => ({...prev,[e.target.name]: e.target.value}));
    }

    useEffect(() => {
        passwordLength();
        passwordsMatch();
    },[newFormData.password,newFormData.password2]);

    useEffect(() => {
        handleCheckLogin();
    },[]);

    const checkEmailAlreadyInUse = async (email) => {
        return fetch(backendEndpoint+'/emailCheck',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({email: email}),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            return recObj.status;
        })
        .catch((err) => console.log(err));
    }

    const handleAddUser = async () => {
        setLoading(true);
        fetch(backendEndpoint+'/usersSelf',{
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(newFormData),
            credentials: "include"
        })
        .then((res) => res.text())
        .then((data) => {
            const recObj = JSON.parse(data);
            setCreatedAccount(recObj.status);
            setNextPage(true);
            setLoading(false);
        })
        .catch((err) => console.log(err));
    }

    const registerFunction = async (e) => {
        setLoading(true);
        e.preventDefault();
        const emailInUse = await checkEmailAlreadyInUse(newFormData.email);
        setLoading(false);
        if(emailInUse){
            alert('Email already in use');
        } else {
            if(newFormData.firstName === '' || newFormData.lastName === '' || newFormData.email === '' || newFormData.password === '' || newFormData.password2 === '' || newFormData.defaultCountry === '' || newFormData.defaultBrand === ''){
                alert(`All fields must be completed before submitting`);
            } else {
                if(!policyAccepted){
                    alert(`The Privacy Policy must be accepted before submitting`);
                } else {
                    handleAddUser();
                }
            }
        }
    }
    const navigatelogin = () => {
        navigate('/hiq');
    }

    const paneSwitcher = () => {
        // return successPage();
        if(createdAccount && nextPage){
            return successPage();
        } else if (!createdAccount && nextPage) {
            return errorPage();
        } else {
            return registerForm();
        }
    }
    const successPage = () => {
        return (
            <div className={styles.paneLower2}>
                <p>Account created successfully, awaiting authorisation</p>
            </div>
        )
    }
    const errorPage = () => {
        return (
            <div className={styles.paneLower2}>
                <p>Error. Please try again</p>
            </div>
        )
    }
    const registerForm = () => {
        return(
            <form onSubmit={registerFunction} className={styles.paneLower}>
                <h2>Register</h2>
                <div className={styles.passwordPane}>
                    <label for="email">Email</label>
                    <input id="email" type="text" name="email" onChange={handleChange} value={newFormData.email}/>
                    <div className={styles.namesDiv}>
                        <div>
                            <label for="firstName">First Name</label>
                            <input id="firstName" type="text" name="firstName" onChange={handleChange} value={newFormData.firstName}/>
                        </div>
                        <div>
                            <label for="lastName">Last Name</label>
                            <input id="lastName" type="text" name="lastName" onChange={handleChange} value={newFormData.lastName}/>
                        </div>
                    </div>
                    <hr/>
                    <label for="password">Password</label>
                    <input id="password" type="password" name="password" onChange={handleChange} value={newFormData.password}/>
                    <p className={styles.errorMessage} style={{color: `${passLength? 'green' : 'red'}`, display: newFormData.password.length === 0? 'none' : 'block'}}>{passLength? `Password is at least 8 characters` : `Password must be at least 8 characters`}</p>
                    <label for="password2">Re-Type</label>
                    <input id="password2" type="password" name="password2" onChange={handleChange} value={newFormData.password2}/>
                    <p className={styles.errorMessage} style={{color: `${passMatch? 'green' : 'red'}`,display: newFormData.password2.length === 0? 'none' : 'block'}}>{passMatch? 'Passwords Match' : `Passwords Don't Match`}</p>
                    <hr/>
                    <div className={styles.acceptPolicy}>
                        <input type="checkbox" id="acceptPolicy" onChange={handleCheckboxChange} isChecked={policyAccepted}/>
                        <p className={styles.statement}><label for="acceptPolicy">I have read and accept the </label><PrivacyPolicyViewer/></p>
                    </div>
                </div>
                <div className={styles.footer}>
                    <input type="submit" value="Create Account" disabled={!policyAccepted}/>
                    <button type="button" onClick={navigatelogin}>Cancel</button>
                </div>
            </form>
        )
    }

    return(
        <>
            <main className={`${styles.background} ${styles.hiq}`}>
                <div className={styles.overlay}></div>
                <div className={styles.paneContainer}>
                    <div className={styles.pane}>
                        <div className={styles.paneUpper}>
                        <span><strong>Goodyear</strong> Retail Brand Hub</span><div className={styles.divider}></div><div className={styles.svgCon}><HiQlogo/></div>
                        </div>
                        {paneSwitcher()}
                    </div>
                </div>
            </main>
            <HiQFooterExternal />
        </>
    )
}