import React, { useState , useEffect, useContext } from "react";
import styles from './Root.module.css';
import Header from "../header/Header";
import Footer from "../footer/Footer";
import BrandCountrySelect from "../brandCountrySelect/BrandCountrySelect";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { LoggedInContext } from "../providers/LoggedInContext";
import { BrandCountryContext } from "../providers/BrandCountryProvider";
import LoadingScreen from "../loadingScreen/LoadingScreen";
import HiQHeader from "../header/HiQHeader";
import HiQBrandCountrySelect from "../brandCountrySelect/HiQBrandCountrySelect";
import HiQFooter from "../footer/HiQFooter";

export default function HiQRoot(){
    const {user} = useContext(LoggedInContext);
    const{changerActive,setChangerActive} = useContext(BrandCountryContext);

    return (
        <>
            <HiQHeader/>
            <HiQBrandCountrySelect />
            <main>
                <div className={`${styles.mainOverlay} ${changerActive && user.viewAllCountry === 1? styles.changerActive : ''}`}></div>
                <Outlet/>
            </main>
            <HiQFooter/>
        </>
    );
}